import Head from "next/head";

import styles from "../styles/Home.module.css";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";

import { getSession } from "next-auth/react";
import { useEffect } from "react";
import { PostHog } from "posthog-node";
import { parse } from "cookie";
import { handleLoggedIn } from "../utils/serverProps";

const projectAPIKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;

const posthogClient = new PostHog(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
  personalApiKey: process.env.NEXT_PUBLIC_POSTHOG_PERSONAL_KEY,
  host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  autocapture: false,
  featureFlagsPollingInterval: 300000,
  advanced_disable_feature_flags_on_first_load: true,
});

const getDistinctId = function (context) {
  const cookies = parse(context.req.headers.cookie || "");
  const cookieKey = `ph_${projectAPIKey}_posthog`;
  let distinctId;
  if (cookies[cookieKey]) {
    try {
      distinctId = JSON.parse(cookies[cookieKey]).distinct_id;
    } catch (error) {}
  } else {
    distinctId = null;
  }
  return distinctId;
};

export async function getServerSideProps(context) {
  const session = await getSession(context);
  let { req } = context;
  const path = req.url.split("?")[0];
  const query = req.url.split("?")[1];
  if (session) {
    return {
      redirect: {
        destination: query ? `/workspace?${query}` : `/workspace`,
        permanent: false,
      },
    };
  }

  return {
    props: { session },
  };
}

export default function Home(props) {
  const router = useRouter();

  const queryString = router.asPath.replace(router.pathname, "");
  const returnToValue = router.query.returnTo;
  const returnToValueWithoutSlash =
    (returnToValue && returnToValue.replace("/", "")) || "workspace";

  useEffect(() => {
    if (props.session) {
      router.push("/workspace");
    } else {
      const callbackUrl = `${process.env.NEXT_PUBLIC_SITE_URL}/${returnToValueWithoutSlash}${queryString}`;
      if (router.query.action === "signup") {
        if (router.query.email_id) {
          signIn(undefined, {
            callbackUrl,
            email: router.query.email_id,
          });
        } else {
          signIn(undefined, { callbackUrl });
        }
      } else {
        if (router.query.email_id) {
          signIn(undefined, {
            callbackUrl,
            email: router.query.email_id,
          });
        } else {
          signIn(undefined, { callbackUrl });
        }
      }
      try {
        if (typeof window !== "undefined" && window.localStorage) {
          localStorage.setItem("loginAttempt", "1");
        }
      } catch (e) {
        console.error("LocalStorage access denied:", e);
      }
    }
    document.addEventListener("touchstart", function () {}, true);
  }, []);

  return (
    <div className={styles.container}>
      <Head>
        <title>Beatoven</title>
        <meta name="description" content="Beatoven" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,700;1,400&family=Play:wght@400;700&display=swap"
          rel="stylesheet"
        ></link>

        <link
          href="https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        ></link>
      </Head>

      <main class="text-center font-montsterrat">
        <img
          class="px-3 w-full h-auto md:w-auto md:px-0 md:h-24"
          src="/banner.png"
        />
        <h2 class="mt-2 animate-pulse">
          Redirecting you to{" "}
          {router.query.action === "signup" ? "signup" : "login"} page
        </h2>
      </main>
    </div>
  );
}
